import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, AsyncSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Answer, Report, ReportImpl, Survey, ReportReview } from '@app/_models';
import { Api } from './api.service';
import { Utils } from '@utils/utils';
import { AnswerAnalyzer } from '@utils/answer_analyzer';
import { ScenarioService } from './scenario.service';
import { ReportBuilder } from '@utils/report_builder';


@Injectable({
    providedIn: 'root'
})
export class AnswerService {
  answerId: number;
  totalPages: number;

  private answerBS = new BehaviorSubject<Answer>(null);
  answer = this.answerBS.asObservable();
  calculations: any = {};

  reportDefs: Report[] = [];
  reportList: ReportImpl[] = [];
  reportsBS = new BehaviorSubject<ReportImpl[]>([]);
  reports = this.reportsBS.asObservable();
  reportsComplete = new AsyncSubject<boolean>();
  calculationsComplete = new AsyncSubject<boolean>();
  previousId: string;
  selectionChangeBS = new BehaviorSubject<boolean>(false);
  selectionChange = this.selectionChangeBS.asObservable();

  mutableAssets1: any[];
  mutableAssets2: any[];
  mutableAssets3: any[];
  // all this info is here for sharing between components
  // navigating menu tree in form
  private currentItemBS = new BehaviorSubject<any>(null);
  currentItem = this.currentItemBS.asObservable();

  private currentAssetListBS = new BehaviorSubject<any>(null);

  private nodeList: any[];

    constructor(
        private zone: NgZone,
        public api: Api,
        private scenarioService: ScenarioService) {

    }

    public clearReportList(): void {
        this.reportList = [];
        this.reportsBS = new BehaviorSubject<ReportImpl[]>([]);
    }

    public setCurrentItem(item: any): void {
      this.currentItemBS.next(item);
    }
    public getCurrentItem(): any {
      return this.currentItemBS.value;
    }
    public findAndSetCurrentItem(uid: string): void {
        if (this.answerValue) {
            Utils.jsonTraverse(this.answerValue, (key, value, scope) => {
                if (key === 'uid' && value === uid) {
                    this.setCurrentItem(scope);
                }
            });
        }
    }

    public setCurrentAssetList(list: any): void {
      this.currentAssetListBS.next(list);
    }

    public getCurrentAssetList(): any {
      return this.currentAssetListBS.value;
    }

    // Used for Government Property and Facility Asset Type
    public get answerValue(): Answer {
      return this.answerBS.value;
    }

    public resetAnswer(): void {
        this.answerBS.next(null);
        this.answerId = null;
    }

    findDisplayCalculations(q: any): any[] {
      const inlineCalcs = [];
      if (this.answerValue && this.calculationsComplete) {
        Utils.jsonTraverse(this.answerValue, (key, value, scope) => {
          if (key === 'calculations') {
            scope.calculations.forEach(c => {
              if (c.display_meta && c.display_meta.display_nodes) {
                const entityIds = q.ancestors || [];
                entityIds.push(q.uid);
                const matches = entityIds.filter(e => c.display_meta.display_nodes.some(n => e === n));
                if (matches.length > 0) {
                  if (inlineCalcs.filter(x => x.id === c.id).length === 0) {
                    inlineCalcs.push(c);
                  }
                }
              }
            });
          }
        });
      }
      inlineCalcs.sort((a, b) => a.display_meta.priority - b.display_meta.priority);
      return inlineCalcs;
    }

    getClassifications(): Observable<any> {
        return this.api.get('classifications', {});
    }

    getGuidances(): Observable<any> {
        return this.api.get('guidances', {});
    }

    // queries for answers
    getSurveys(params?: any): Observable<Survey[]> {
        return this.api.get('surveys', params);
    }

    // queries for answers
    query(params?: any): Observable<Answer[]> {
        const res = this.api.get('survey_answers', params);

        return res.pipe(
            tap(result => { this.totalPages = result.total_pages; }),
            map(result => result.survey_answers)
        );
    }

    // gets the answer.
    getAnswer(id: any, params?: any): Observable<Answer> {
        this.currentItemBS.next(null); // reset
        if (this.answerId === id) {
            return this.answer;
        }
        else {
          this.answerId = id;
          this.nodeList = [];
          this.answerBS.next(null); // reset while loading
          return this.api.get('survey_answers/' + id, params).pipe(
              tap(res => {
                  this.answerId = res.id;
                  this._buildNodeList(res);
                  this.answerBS.next(res);
              }),
              map(res => res)
          );
        }
    }

    queryReports(answerId: number, params?: any): Observable<Report[]> {
        if (params) {
            params.survey_answer_id = answerId;
        } else {
            params = { survey_answer_id: answerId };
        }
        return this.api.get('reports', params);
    }

    /** retrieves report from db, then processes calculations */
    getReport(id: any, params?: any): Observable<Report> {
        return this.api.get('reports/' + id, params, );
    }

    /** retrieves report from db, then processes calculations */
    saveReportReview(params?: any): Observable<ReportReview> {
        return this.api.post('report_reviews', params);
    }

    /** retrieves report from db, then processes calculations */
    printReport(params?: any): Observable<any> {
        return this.api.post('reports/as_pdf', params, { responseType: 'blob'});
    }

    /** saves an  answer to a survey (update only)
     * Not currently being used as auto-save is fulltime right now.
     * when placed into service, need to first make changes to _convertAnswerToDb to match new schema
    */

    save(): Observable<Answer> {
      const answer = this.answerBS.value;
      const rAnswer = this._convertAnswerToDb(answer);
      return this.api.put('survey_answers/' + answer.id, { survey_answer: rAnswer }).pipe(
          tap(res => this.answerBS.next(res)),
          map(res => res)
      );
    }

    delete(answerId: number): void {
       this.api.delete('survey_answers/' + answerId, { })
        .subscribe(res => {
          if (answerId === this.answerId) {
            this.answerBS.next(null);
            this.answerId = null;
          }
        });
    }

    finalize(): Observable<Answer> {
      const answer = this.answerBS.value;
      return this.api.post('survey_answers/finalize/' + answer.id, { survey_answer: { status: 'final' }, results: this.calculations }).pipe(
          tap(res => this.answerBS.next(res)),
          map(res => res)
      );
    }

    saveReview(review: ReportReview): void {
        this.api.post('report_reviews/', { report_review: review }).subscribe({
            error: error => {
                console.error('There was an error!', error);
            }
        });
    }

    // right now returns "{message: success}"
    toggleAssetType(assetTypeId: number, isOn: boolean): Observable<any> {
        return this.api.get('survey_answers/toggle_asset_type/' + this.answerId,
            { asset_type_id: assetTypeId, is_on: isOn });
    }

  deselectAllAssetType(assetTypeId: number): Observable<any> {
    return this.api.put('survey_answers/deselect_asset_type/' + this.answerId, { asset_type_id: assetTypeId});
  }

    // right now returns "{message: success}"
    toggleOption(optionId: number, isOn: boolean, layer?: number): Observable<any> {
        return this.api.get('survey_answers/toggle_question_option/' + this.answerId,
            { question_option_id: optionId, is_on: isOn, layer: layer });
    }
    /**
     * Add layer, specify if you're saving or not.
     */
    addLayer(saveToDb: boolean, copyFrom?: number): void {
        if (saveToDb) {
            this._addLayerUsingApi(copyFrom);
        } else {
            this._addLayerWithoutSaving(copyFrom);
        }
    }

    /** adds layer without saving to db. */
    private _addLayerWithoutSaving(copyFrom?: number): void {
      const layerIdx = (copyFrom) ? copyFrom - 1 : 0;
      const answer = this.answerBS.value;
      const layerToCopy = answer.layers[layerIdx];
      const newLayer = JSON.parse(JSON.stringify(layerToCopy));

      answer.number_layers++;
      newLayer.number = answer.number_layers;

      Utils.jsonTraverse(newLayer, (key, value, scope) => {
          if (key === 'layer') {
              scope[key] = answer.number_layers;
          }
      });
      answer.layers.push(newLayer);
      this._buildNodeList(answer);
    }

    /** adds layer through api */
    private _addLayerUsingApi(copyFrom?: number): void {
        const params = (copyFrom) ? { copy_layer: copyFrom } : {};
        this.api.get('survey_answers/add_layer/' + this.answerId, params)
            .pipe(tap(res => {
                    this.answerBS.next(res);
                    this._buildNodeList(res);
                }),
                map(res => res))
            .subscribe({
                error: error => {
                    console.error('There was an error!', error);
                }
            });
    }

    removeLayer(saveToDb: boolean): void {
        // default for now - save to DB

        this.currentItemBS.next(null);
        if (saveToDb) {
            this.api.get('survey_answers/remove_layer/' + this.answerId, {})
                .pipe(tap(res => {
                    this.answerBS.next(res);
                    this._buildNodeList(res);

                }),
                    map(res => res))
                .subscribe({
                    error: error => {
                        console.error('There was an error!', error);
                    }
                });
        } else {
            // add this
        }
    }

    add(params: any): Observable<any>{
        return this.api.post('survey_answers', params).pipe(
            tap(res => this.answerBS.next(res)),
            map(res => res)
        );
    }

    firstNode(): string {
        return this.nodeList[0];
    }
    lastNode(): string {
        return this.nodeList[this.nodeList.length - 1];
    }

    // moving around the form using the next and back buttons
    progressBack(): void {
      const v = this.currentItemBS.value;

      const nodeIdx = this.nodeList.indexOf(v);
      if (nodeIdx > 0) {
          this.setCurrentItem(this.nodeList[nodeIdx - 1]);
      }
    }

    progressNext(): void {
      const v = this.currentItemBS.value;
      if (!v.complete) { // run if not already completed
          v.complete = true; // set item as completed
          // auto save to db
          const params = {node_type: v.type, node_id: v.id};
          if (v.layer) { params['layer'] = v.layer; }
          this.api.get('/survey_answers/mark_progress/' + this.answerId, params).toPromise();
      }

      const nodeIdx = this.nodeList.indexOf(v);
      if (nodeIdx < this.nodeList.length - 1) {
          this.setCurrentItem(this.nodeList[nodeIdx + 1]);
      }
    }

    // include nick if applies to a scenario - designed for timeline analysis
    goToNode(uid: string, previousId?: string, ref?: string): void {
      let rnode = this.nodeList.find(node => node.uid === uid);
      this.previousId = previousId;
      if (!rnode) {
        if (ref) {
          const a = this.answerValue;
          const scen = this.scenarioService.getScenario;
          Utils.jsonTraverse(a, (key, value, scope) => {
            if (key === 'uid' && value === uid) {
              // find child
              scope.questions.forEach(q => {
                q.question_options.forEach(o => {
                  if (o.scenarios.includes(scen.nick)) {
                    rnode = q;
                  }
                });
              });
            }
          });
        }
        else {
          rnode = this.nodeList.find(node => node.ancestors && node.ancestors.includes(uid));
        }
      }
      if (rnode) {
        this.setCurrentItem(rnode);
      }
    }

    findNode(ancestors: string[]): any {
      return this.nodeList.find(node => ancestors.includes(node.uid));
    }

    // build list for going forward and back in form
    _buildNodeList(a: Answer): void {
        this.nodeList = [];
        a.progress = {sections: 0, complete: 0};

        Utils.jsonTraverse(a.questions, (key, value, scope) => {
        if (scope.global === true) {
          if (key === 'menu_item' && value === true) {
            this.nodeList.push(scope);
            a.progress.sections++;
            if (scope.complete) { a.progress.complete++; }
          }
        }
      });
        // probably should be ordered in json - but here we are
        Utils.jsonTraverse(a.asset_types, (key, value, scope) => {
            if (key === 'menu_item' && value === true) {
              this.nodeList.push(scope);
              a.progress.sections++;
              if (scope.complete) { a.progress.complete++; }
            }
        });

        Utils.jsonTraverse(a.layers, (key, value, scope) => {
            if (key === 'menu_item' && value === true) {
              this.nodeList.push(scope);
              a.progress.sections++;
              if (scope.complete) { a.progress.complete++; }
            }
        });

        Utils.jsonTraverse(a.questions, (key, value, scope) => {
          if (scope.global === false) {
            if (key === 'menu_item' && value === true) {
              this.nodeList.push(scope);
              a.progress.sections++;
              if (scope.complete) { a.progress.complete++; }
            }
          }
        });
    }

    checkProgress(): boolean {
        const a = this.answerValue;

        a.progress = { sections: 0, complete: 0 };

        a.progress.sections = this.nodeList.length;
        a.progress.complete = this.nodeList.reduce((cnt, node) => cnt + ( (node.complete) ? 1 : 0), 0);

        return a.progress.sections === a.progress.complete;
    }

    goToFirstIncompleteNode(): boolean {
        let found = false;

        const node = this.nodeList.find(node => !node.complete);
        if (node) {
            found = true;
            this.setCurrentItem(node);
            this.setCurrentAssetList(node.asset_types);
        }

        return found;
    }

    isComplete(node: any): boolean {
        let isDone = true;
        Utils.jsonTraverse(node, (key, value, scope) => {
            if (key === 'complete' && value === false) {
                isDone = false;
            }
        });
        return isDone;
    }

    isSelected(node: any): boolean {
        let isDone = false;
        Utils.jsonTraverse(node, (key, value, scope) => {
            if (key === 'selected' && value === true) {
                isDone = true;
            }
        });

        return isDone;
    }

    private _convertAnswerToDb(answer: Answer): any {
        const rAnswer: any = { number_layers: answer.number_layers, asset_types: [], answers: [] };
        // loop through answer (which contains survey information and extract only necessary info for saving)
        Utils.jsonTraverse(answer, (key, value, scope) => {
            if (key === 'type' && value === 'AssetType') {
                if (scope.selected) {
                    rAnswer.asset_types.push(scope.id);
                }
            }
            if (key === 'question_options') {
                for (let index = 0; index < value.length; index++) {
                    if (value[index].selected === true) {
                        const js: any = { question_option_id: value[index].id };
                        if (value[index].layer) {
                            js.layer = value[index].layer;
                        }
                        rAnswer.answers.push(js);
                    }
                }
            }
        });
        return rAnswer;
    }

    /**   CALCULATIONS  **/
    /*
     * Run calculations against all available scenarios
     * Populates this.calculations
     */
    runAllCalculations(answerId: number, runReports?: boolean, reportQuery?: any): void {
        this.calculations = {};
        this.calculationsComplete.next(false);

        // use only scenarios actively selected through assets
        let snicks = [];
        Utils.jsonTraverse(this.answerValue.asset_types, (key, value, scope) => {
            if (key === 'selected' && value === true) {
                snicks = [...new Set([...snicks , ...scope.scenarios])];
            }
        });

        const doNext = (offset) => {
            this.runAnswerCalculations(snicks[offset]).then(f => {
                offset++;

                if (offset < snicks.length) {
                    doNext(offset);
                } else {
                  this.calculationsComplete.next(true);
                  this.calculationsComplete.complete();
                  if (runReports) {
                      this.runReports(answerId, reportQuery);
                  }
                }
            });
        };
        doNext(0);
    }

    /* run the answer against the provided scenarios nick creating a list of calculations
     * {'calc_1_101': 0.23, ...}
     */
    runAnswerCalculations = async (nick: any): Promise<any> => {
      if (nick) {
        const answer = this.answerBS.value;
        const util = new AnswerAnalyzer(answer, nick);

        return util.getValues().then((res: any) => {
          let snick = nick;

          if (typeof snick === 'object'){
            snick = nick.nick;
          }
          this.calculations[snick] = {...this.calculations[snick], ...res };

          return new Promise(resolve => {resolve(this.calculations[nick])});
        });
      }
    }

    // call when you want to run answer calculations for current nick
    runCurrentAnswerCalculations = async (): Promise<any> => {
        const s = this.scenarioService.getScenario;
        if (s) { await this.runAnswerCalculations(s.nick); }
    }

    runReports(answerId: number, reportQuery?: any): void {
        const q = (reportQuery) ? reportQuery : {inline: false};
        // get scenarios for the selected assets only. do this here so its only done once
        const okNicks = Object.keys(this.calculations);
        this.reportsComplete.next(false);

        this.scenarioService.getScenarios(this.answerValue.survey_id).subscribe(res => {
            const scenarios = res.filter(s => okNicks.indexOf(s.nick) > -1);

            if (this.reportList.length === 0) {

                this.queryReports(answerId, reportQuery)
                    .subscribe(rpts => {
                        this.reportDefs = rpts;
                        const max = rpts.length - 1;
                        rpts.forEach((r, idx) => {

                            this.runReport(r, scenarios).then( res => {
                                this.reportList.push(res);
                                this.reportsBS.next(this.reportList);

                                if (idx === max) {
                                    this.reportsComplete.next(true);
                                    this.reportsComplete.complete();
                                }
                            });
                        });
                    });
            }
        });
    }

    // must ensure all calculations have been run prior to calling this
    runReport(report: Report, scenarios?: any[]): Promise<ReportImpl> {
        const answer = this.answerBS.value;
        if (typeof scenarios === 'undefined') {
          if (report.meta.context === 'Scenario') {
            scenarios = [this.scenarioService.getScenario];
          } else {
            scenarios = this.scenarioService.scenarios;
          }
        }
        const util = new ReportBuilder(answer, report, scenarios);
        return util.getReport(this.calculations);
    }

    refreshSurveyAnswer(answerId: number): void {
      this.api.get('survey_answers/' + answerId, {}).pipe(
        tap(res => {
          this.answerId = res.id;
          this.answerBS.next(res);
        }),
        map(res => res)
      ).subscribe({
        error: error => {
          console.error('There was an error!', error);
        }
      });
    }

  updateSurveyAnswerAssetType(surveyAnswer: any, assetType: any): Observable<any> {
    const v = this.currentItemBS.value;
    if (!v.complete || !v.applicable){
      v.complete = !v.complete;
      v.applicable = !v.applicable;
    }
    const params = {asset_type_id: assetType.id};
    return this.api.put('survey_answers/asset_type_active/' + surveyAnswer.id, params);
  }

  updateSurveyAnswerAssetPL(plValue: number): void{
    // Get PMA TEO and FHTEO asset types to update pl values
    const PMAAssets = this.getSurveyAnswerAssetTEOPMA();
    // Set mutable assets arrays to empty
    this.mutableAssets1 = [] ;
    this.mutableAssets2 = [] ;
    this.mutableAssets3 = [] ;

    // Add asset related to each level of mutable properties 3,2,1
    this.getAssetsMutableProperty(PMAAssets);

    // All PMA assets have the same PL value
    // Transmission Equipment in Operation (TEO) and Facilities Housing Transmission Equipment in Operation (FHTEO)
    PMAAssets.forEach((assets: any) => {
      Utils.jsonTraverse(assets.asset_types, (key, value, scope) => {
        if (key === 'PL'){
          scope.PL = plValue.toString();
        }
      });
    });

    // Update mutable assets top level risks to be adjusted VH, H, M or 0.9, 0.7, 0.5
    Utils.jsonTraverse(this.mutableAssets3, (key, value, scope) => {
      if (key === 'Consequence') {
        if (plValue === 5) {
          scope.Consequence = 'VH';
        } else if (plValue === 6) {
          scope.Consequence = 'H';
        } else {
          scope.Consequence = 'M';
        }
      }
      if (key === 'TA1 Consequence Numerical') {
        if (plValue === 5) {
          scope['TA1 Consequence Numerical'] = '0.9';
        } else if (plValue === 6) {
          scope['TA1 Consequence Numerical']  = '0.7';
        } else {
          scope['TA1 Consequence Numerical']  = '0.5';
        }
      }
    });

    // Update mutable assets top level risks to be adjusted H, M, L or 0.7, 0.5, 0.3
    Utils.jsonTraverse(this.mutableAssets2, (key, value, scope) => {
      if (key === 'Consequence') {
        if (plValue === 5) {
          scope.Consequence = 'H';
        } else if (plValue === 6) {
          scope.Consequence = 'M';
        } else {
          scope.Consequence = 'L';
        }
      }
      if (key === 'TA1 Consequence Numerical') {
        if (plValue === 5) {
          scope['TA1 Consequence Numerical'] = '0.7';
        } else if (plValue === 6) {
          scope['TA1 Consequence Numerical']  = '0.5';
        } else {
          scope['TA1 Consequence Numerical']  = '0.3';
        }
      }
    });

    // Update mutable assets top level risks to be adjusted M, L, VL or 0.5, 0.3, 0.1
    Utils.jsonTraverse(this.mutableAssets1, (key, value, scope) => {
      if (key === 'Consequence') {
        if (plValue === 5) {
          scope.Consequence = 'M';
        } else if (plValue === 6) {
          scope.Consequence = 'L';
        } else {
          scope.Consequence = 'VL';
        }
      }
      if (key === 'TA1 Consequence Numerical') {
        if (plValue === 5) {
          scope['TA1 Consequence Numerical'] = '0.5';
        } else if (plValue === 6) {
          scope['TA1 Consequence Numerical']  = '0.3';
        } else {
          scope['TA1 Consequence Numerical']  = '0.1';
        }
      }
    });
  }

  // Get PMA TEO and FHTEO asset types to update pl values
  // These are influenced by the Facilities and Equipment Protection Level (PL)
  getSurveyAnswerAssetTEOPMA(): any{
    let TEO = null;
    let FHTEO = null;
    this.answerBS.value.asset_types.forEach((assetType: any) => {
      if (assetType.nick === 'G'){
        assetType.asset_types.forEach((asset: any) => {
          asset.asset_types.forEach(
            (subAsset: any) => {
              if (subAsset.nick === 'TEO'){
                TEO = subAsset;
              }
              if (subAsset.nick === 'FHTEO'){
                FHTEO = subAsset;
              }
            }
          );
        });
      }
    });
    return [TEO, FHTEO];
  }

  // Cycle through TEO and FHTEO Array of assets
  // Assets with mutable properties need the adjectival and consquences updated
  getAssetsMutableProperty(aAssets): void{
      if (aAssets !== undefined){
        aAssets.forEach((asset: any) => {
          this.findMutableAsset(asset);
        });
      }
  }

  // Add child asset where has_mutable_property
  findMutableAsset(asset: any): void{
    if (asset.asset_types){
      asset.asset_types.forEach((child: any) => {
        if (child.mutable_property === 3) {
          this.mutableAssets3.push(child);
        }
        else if (child.mutable_property === 2){
          this.mutableAssets2.push(child);
          }
        else if (child.mutable_property === 1){
          this.mutableAssets1.push(child);
        }
        this.findMutableAsset(child);
      });
    }
  }

  // Get list of surveys that can be copied by relative survey
  getCopySurveys(surveyAnswerId: number): Observable<any> {
    let params = {};
    if (surveyAnswerId !== undefined) {
      params = { copy_survey_answer_id: surveyAnswerId };
    } else {
        params = { copy_survey_answer_id: 0 };
    }
    return this.api.get('surveys/copy_survey_list/', params);
  }
  // Get list of survey answers that can be copied based on survey selected
  getCopySurveyAnswers(surveyId: number): Observable<any> {
    let params = {};
    if (surveyId !== undefined) {
      params = { survey_id: surveyId };
    }
    return this.api.get('survey_answers/copy_list/', params);
  }
}
