import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs';
import { environment } from '@env/environment'

@Injectable()
export class Api {
    apiBase: string = environment.apiUrl + 'v1/';

    constructor(public http: HttpClient) {
    }

    get(endpoint: string, params?: any, reqOpts?: any): Observable<any> {
        if (!reqOpts) {
            reqOpts = { params: new HttpParams() };
        }
        if (params) {
            reqOpts.params = new HttpParams();
            for (const k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }
        reqOpts.headers = this.getHeaders();
        return this.http.get(
            this.apiBase + endpoint, reqOpts
        );
    }

    post(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        if (!reqOpts) {
            reqOpts = {};
        }
        reqOpts.headers = this.getHeaders();
        return this.http.post(this.apiBase + '/' + endpoint, body, reqOpts);
    }

    put(endpoint: string, body: any, reqOpts?: any): Observable<any> {
        if (!reqOpts) {
            reqOpts = {}
        }
        reqOpts.headers = this.getHeaders();

        return this.http.put(this.apiBase + '/' + endpoint, body, reqOpts);
    }

    delete(endpoint: string, reqOpts?: any) {
        return this.http.delete(this.apiBase + '/' + endpoint, reqOpts);
    }

    patch(endpoint: string, body: any, reqOpts?: any) {
        return this.http.patch(this.apiBase + '/' + endpoint, body, reqOpts);
    }


    login(opts: any) {
        return this.http.post(environment.apiUrl + '/auth/login', opts);
    }

    private getHeaders(): HttpHeaders {
        //'Authorization': 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE2MTgzNTU3ODR9.tTuNDRjHtEbBFhlha1LsA6wl3cj4LpyrxIYeu5UClzU'
        return new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'responseType': 'json' })
    }

}
